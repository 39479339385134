/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    ul: "ul",
    li: "li",
    p: "p",
    h3: "h3",
    strong: "strong",
    em: "em"
  }, _provideComponents(), props.components), {Boss, Trait, Warning, Skill, Boon, Item, Instability, SpecialActionKey, Specialization, Condition} = _components;
  if (!Boon) _missingMdxReference("Boon", true);
  if (!Boss) _missingMdxReference("Boss", true);
  if (!Condition) _missingMdxReference("Condition", true);
  if (!Instability) _missingMdxReference("Instability", true);
  if (!Item) _missingMdxReference("Item", true);
  if (!Skill) _missingMdxReference("Skill", true);
  if (!SpecialActionKey) _missingMdxReference("SpecialActionKey", true);
  if (!Specialization) _missingMdxReference("Specialization", true);
  if (!Trait) _missingMdxReference("Trait", true);
  if (!Warning) _missingMdxReference("Warning", true);
  return React.createElement(React.Fragment, null, React.createElement(Boss, {
    name: "mama",
    video: "YMy7HUTQEJI",
    videoCreator: "Roach [dT]",
    foodId: "43360",
    utilityId: "50082",
    healId: "14401",
    utility1Id: "29941",
    utility2Id: "14405",
    utility3Id: "14407",
    eliteId: "30343",
    weapon1MainAffix: "Berserker",
    weapon1MainType: "Axe",
    weapon1MainSigil1: "Force",
    weapon1MainInfusion1Id: "37131",
    weapon1OffAffix: "Berserker",
    weapon1OffType: "Axe",
    weapon1OffSigil: "Impact",
    weapon1OffInfusionId: "37131",
    weapon2OffAffix: "Berserker",
    weapon2OffType: "Mace",
    weapon2OffSigil: "Paralyzation",
    weapon2OffInfusionId: "37131",
    weapon2MainAffix: "Berserker",
    weapon2MainType: "Axe",
    weapon2MainSigil1: "Severance",
    weapon2MainInfusion1Id: "37131",
    video: "YMy7HUTQEJI",
    videoCreator: "Roach [dT]",
    foodId: "43360",
    utilityId: "50082",
    healId: "14401",
    utility1Id: "29941",
    utility2Id: "14405",
    utility3Id: "14407",
    eliteId: "30343",
    weapon1MainInfusion1Id: "37131",
    weapon1OffInfusionId: "37131",
    weapon2OffInfusionId: "37131",
    weapon2MainInfusion1Id: "37131",
    weapon1MainId: "46759",
    weapon1MainSigil1Id: "24615",
    weapon1OffId: "46759",
    weapon1OffSigilId: "24868",
    weapon2MainId: "46759",
    weapon2MainSigil1Id: "84505",
    weapon2OffId: "46766",
    weapon2OffSigilId: "24639"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Take ", React.createElement(Trait, {
    id: "1977",
    id: "1977"
  }), "!"), "\n")), "\n", React.createElement(Warning, null, React.createElement(_components.p, null, "This fight gets very messy if the adds are not immediately CCed. Especially at this fight it is recommended to grab extra CC skills to make up for the lack of CC of your team mates.")), "\n", React.createElement(_components.p, null, "This guide is a PuG friendly version without banner precast. Please remember that CCing all bars fast is the most important bit in this fight. You won't get a good kill - might even wipe your party - if you greed DPS without CC."), "\n", React.createElement(_components.p, null, "There is an invisible hitbox in the center of the arena allowing you to gain enough adrenaline for one ", React.createElement(Skill, {
    name: "decapitate",
    id: "30851"
  }), " with only two critical hits!"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "1st Phase")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "While approaching MAMA cast ", React.createElement(Skill, {
    name: "Banner of Strength",
    id: "14405"
  }), " and ", React.createElement(Skill, {
    name: "Banner of Discipline",
    id: "14407"
  }), "."), "\n", React.createElement(_components.li, null, "Shortly before MAMA becomes vulnerable use ", React.createElement(Skill, {
    name: "mending",
    id: "14401"
  }), "."), "\n", React.createElement(_components.li, null, "Precast ", React.createElement(Skill, {
    name: "Tremor",
    id: "14415"
  }), " for CC."), "\n", React.createElement(_components.li, null, "Use ", React.createElement(Skill, {
    name: "headbutt",
    id: "30343"
  }), " to CC quickly."), "\n", React.createElement(_components.li, null, "Engage ", React.createElement(Skill, {
    name: "Berserk",
    id: "30185"
  }), "."), "\n", React.createElement(_components.li, null, "Swap."), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "decapitate",
    id: "30851"
  }), ", continue as usual."), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.em, null, "1st Add")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "CC with ", React.createElement(Skill, {
    name: "Wildblow",
    id: "29941"
  }), "."), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "2nd Phase")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Normal rotation according to the guide."), "\n", React.createElement(_components.li, null, "Don't break this bar."), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.em, null, "2nd Add")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Swap to Maces."), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "skullgrinder",
    id: "29679"
  }), "."), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Mending",
    id: "14401"
  }), " shortly before the add dies."), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "3rd Phase")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Tremor",
    id: "14415"
  }), ", ", React.createElement(Skill, {
    name: "wildblow",
    id: "29941"
  }), "."), "\n", React.createElement(_components.li, null, "Swap to Axes as soon as possible."), "\n", React.createElement(_components.li, null, "Normal rotation according to the guide."), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.em, null, "3rd Add")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Headbutt",
    id: "30343"
  }), "."), "\n", React.createElement(_components.li, null, "Recast ", React.createElement(Skill, {
    name: "Banner of Strength",
    id: "14405"
  }), " and ", React.createElement(Skill, {
    name: "Banner of Discipline",
    id: "14407"
  }), " while approaching the add."), "\n", React.createElement(_components.li, null, "Engage ", React.createElement(Skill, {
    name: "Berserk",
    id: "30185"
  }), "."), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Mending",
    id: "14401"
  }), " shortly before the add dies."), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "4th Phase")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "CC with ", React.createElement(Skill, {
    name: "Wildblow",
    id: "29941"
  }), "."), "\n", React.createElement(_components.li, null, "Use whatever CC is up at this point."), "\n"), "\n", React.createElement(Boss, {
    name: "siax",
    video: "b2Xi8dRE5js",
    videoCreator: "Roach [dT]",
    foodId: "43360",
    utilityId: "50082",
    healId: "14401",
    utility1Id: "30258",
    utility2Id: "14405",
    utility3Id: "14407",
    eliteId: "30343",
    weapon1MainAffix: "Berserker",
    weapon1MainType: "Axe",
    weapon1MainSigil1: "Force",
    weapon1MainInfusion1Id: "37131",
    weapon1OffAffix: "Berserker",
    weapon1OffType: "Axe",
    weapon1OffSigil: "Serpentslaying",
    weapon1OffInfusionId: "37131",
    weapon2MainAffix: "Berserker",
    weapon2MainType: "Greatsword",
    weapon2MainSigil1: "Force",
    weapon2MainSigil2: "Serpentslaying",
    weapon2MainInfusion1Id: "37131",
    weapon2MainInfusion2Id: "37131",
    video: "b2Xi8dRE5js",
    videoCreator: "Roach [dT]",
    foodId: "43360",
    utilityId: "50082",
    healId: "14401",
    utility1Id: "30258",
    utility2Id: "14405",
    utility3Id: "14407",
    eliteId: "30343",
    weapon1MainInfusion1Id: "37131",
    weapon1OffInfusionId: "37131",
    weapon2MainInfusion1Id: "37131",
    weapon2MainInfusion2Id: "37131",
    weapon1MainId: "46759",
    weapon1MainSigil1Id: "24615",
    weapon1OffId: "46759",
    weapon1OffSigilId: "24658",
    weapon2MainId: "46762",
    weapon2MainSigil1Id: "24615",
    weapon2MainSigil2Id: "24658"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "It is recommended to play Greatsword in PuGs so you can cleave down the exploding adds!"), "\n", React.createElement(_components.li, null, "Play ", React.createElement(Trait, {
    name: "Smashbrawler",
    id: "2049"
  }), "!"), "\n")), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "1st Phase")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "While approaching Siax cast ", React.createElement(Skill, {
    name: "Banner of Strength",
    id: "14405"
  }), " and ", React.createElement(Skill, {
    name: "Banner of Discipline",
    id: "14407"
  })), "\n", React.createElement(_components.li, null, "Shortly before you get in combat use ", React.createElement(Skill, {
    name: "mending",
    id: "14401"
  }), "."), "\n", React.createElement(_components.li, null, "Use ", React.createElement(Skill, {
    name: "whirlingaxe",
    id: "14399"
  }), " to quickly gain adrenaline."), "\n", React.createElement(_components.li, null, "As soon as the cast of ", React.createElement(Skill, {
    name: "whirlingaxe",
    id: "14399"
  }), " is finished, pop ", React.createElement(Skill, {
    name: "berserk",
    id: "30185"
  }), " and ", React.createElement(Skill, {
    name: "decapitate",
    id: "30851"
  })), "\n", React.createElement(_components.li, null, "Use ", React.createElement(Skill, {
    name: "headbutt",
    id: "30343"
  }), " and ", React.createElement(Skill, {
    name: "outrage",
    id: "30258"
  }), " to CC quickly."), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "decapitate",
    id: "30851"
  }), ", continue as usual."), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.em, null, "1st and 2nd Add")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Use ", React.createElement(Skill, {
    name: "rush",
    id: "14446"
  }), " to approach your add quickly."), "\n", React.createElement(_components.li, null, "Kill your add with ", React.createElement(Skill, {
    name: "arcdivider",
    id: "29852"
  }), " and ", React.createElement(Skill, {
    name: "hundredblades",
    id: "14554"
  }), " quickly."), "\n", React.createElement(_components.li, null, "When you killed the add use ", React.createElement(Skill, {
    name: "mending",
    id: "14401"
  }), " and rush back to Siax."), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "2nd Phase")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Throw ", React.createElement(Skill, {
    name: "bladetrail",
    id: "14510"
  }), " against the vulnerable Siax."), "\n", React.createElement(_components.li, null, "Use ", React.createElement(Skill, {
    name: "arcdivider",
    id: "29852"
  }), " to cleave down the incoming adds."), "\n", React.createElement(_components.li, null, "Normal rotation according to the guide."), "\n", React.createElement(_components.li, null, "Remember to use ", React.createElement(Skill, {
    name: "headbutt",
    id: "30343"
  }), " and ", React.createElement(Skill, {
    name: "outrage",
    id: "30258"
  }), " for ", React.createElement(Skill, {
    name: "berserk",
    id: "30185"
  }), " extension."), "\n", React.createElement(_components.li, null, "Depending on your group you will drop out of ", React.createElement(Skill, {
    name: "berserk",
    id: "30185"
  }), ". Engage ", React.createElement(Skill, {
    name: "berserk",
    id: "30185"
  }), " as soon as you can again."), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "3rd Phase")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Warrior business as usual."), "\n"), "\n", React.createElement(Boss, {
    name: "ensolyss",
    video: "K6S6yrQcxkY",
    videoCreator: "Roach [dT]",
    foodId: "91805",
    utilityId: "50082",
    healId: "14401",
    utility1Id: "14405",
    utility2Id: "14407",
    utility3: "outrage",
    eliteId: "30343",
    weapon1MainAffix: "Berserker",
    weapon1MainType: "Axe",
    weapon1MainSigil1: "impact",
    weapon1MainInfusion1Id: "37131",
    weapon1OffAffix: "Berserker",
    weapon1OffType: "Axe",
    weapon1OffSigil: "Serpentslaying",
    weapon1OffInfusionId: "37131",
    weapon2MainAffix: "Berserker",
    weapon2MainType: "Mace",
    weapon2MainSigil1: "severance",
    weapon2OffType: "Torch",
    weapon2OffAffix: "Berserker",
    weapon2OffSigil: "paralyzation",
    weapon2MainInfusion1Id: "37131",
    weapon2OffInfusionId: "37131",
    video: "K6S6yrQcxkY",
    videoCreator: "Roach [dT]",
    foodId: "91805",
    utilityId: "50082",
    healId: "14401",
    utility1Id: "14405",
    utility2Id: "14407",
    eliteId: "30343",
    weapon1MainInfusion1Id: "37131",
    weapon1OffInfusionId: "37131",
    weapon2MainInfusion1Id: "37131",
    weapon2OffInfusionId: "37131",
    weapon1MainId: "46759",
    weapon1MainSigil1Id: "24868",
    weapon1OffId: "46759",
    weapon1OffSigilId: "24658",
    weapon2MainId: "46766",
    weapon2MainSigil1Id: "84505",
    weapon2OffId: "46775",
    weapon2OffSigilId: "24639",
    utility3Id: "30258"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Take ", React.createElement(Trait, {
    name: "savageinstinct",
    id: "1977"
  }), "!"), "\n", React.createElement(_components.li, null, "In slow teams (PuGs) bring ", React.createElement(Trait, {
    name: "smashbrawler",
    id: "2049"
  }), " and ", React.createElement(Skill, {
    name: "outrage",
    id: "30258"
  }), "."), "\n", React.createElement(_components.li, null, "In fast teams bring ", React.createElement(Skill, {
    name: "bullscharge",
    id: "14516"
  }), " over ", React.createElement(Skill, {
    name: "signetofmight",
    id: "14404"
  }), "."), "\n")), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "1st Phase")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Get adrenaline with ", React.createElement(Skill, {
    name: "Whirlingaxe",
    id: "14399"
  }), " and open ", React.createElement(Skill, {
    name: "Berserk",
    id: "30185"
  }), " in the dome."), "\n", React.createElement(_components.li, null, "After the dome cast ", React.createElement(Skill, {
    name: "Headbutt",
    id: "30343"
  }), " and ", React.createElement(Skill, {
    name: "outrage",
    id: "30258"
  }), "."), "\n", React.createElement(_components.li, null, "Smash ", React.createElement(Skill, {
    name: "Decapitate",
    id: "30851"
  }), " hard."), "\n"), "\n", React.createElement(_components.p, null, "Pick up your banners - in case you did not precast them - after you phased Ensolyss!"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "66% and 33%")), "\n", React.createElement(_components.p, null, "Swap to your off set (mace/torch) and cap the circle. After you meet in the middle, continue with:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Use ", React.createElement(Skill, {
    name: "Flames of war",
    id: "29940"
  }), " and blast 6 stacks of ", React.createElement(Boon, {
    name: "Might"
  }), " with ", React.createElement(Skill, {
    name: "Banner of Strength",
    id: "14405"
  }), " and ", React.createElement(Skill, {
    name: "Banner of Discipline",
    id: "14407"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Mending",
    id: "14401"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Berserk",
    id: "30185"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Pommelbash",
    id: "14503"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Headbutt",
    id: "30343"
  }), " to trigger ", React.createElement(Item, {
    name: "severance",
    id: "84505"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Swap weapons."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Smash ", React.createElement(Skill, {
    name: "Decapitate",
    id: "30851"
  }), " hard."), "\n"), "\n"), "\n", React.createElement(Boss, {
    name: "skorvald",
    video: "yioeHPEHAK4",
    videoCreator: "Roach [dT]",
    foodId: "91805",
    utilityId: "9443",
    healId: "14401",
    utility1Id: "14405",
    utility2Id: "14407",
    utility3Id: "14404",
    eliteId: "30343",
    weapon1MainAffix: "Berserker",
    weapon1MainType: "Axe",
    weapon1MainSigil1: "impact",
    weapon1MainInfusion1Id: "37131",
    weapon1OffAffix: "Berserker",
    weapon1OffType: "Axe",
    weapon1OffSigil: "Force",
    weapon1OffInfusionId: "37131",
    weapon2MainAffix: "Berserker",
    weapon2MainType: "Mace",
    weapon2MainSigil1: "severance",
    weapon2OffType: "Mace",
    weapon2OffAffix: "Berserker",
    weapon2OffSigil: "paralyzation",
    weapon2MainInfusion1Id: "37131",
    weapon2OffInfusionId: "37131",
    video: "yioeHPEHAK4",
    videoCreator: "Roach [dT]",
    foodId: "91805",
    utilityId: "9443",
    healId: "14401",
    utility1Id: "14405",
    utility2Id: "14407",
    utility3Id: "14404",
    eliteId: "30343",
    weapon1MainInfusion1Id: "37131",
    weapon1OffInfusionId: "37131",
    weapon2MainInfusion1Id: "37131",
    weapon2OffInfusionId: "37131",
    weapon1MainId: "46759",
    weapon1MainSigil1Id: "24868",
    weapon1OffId: "46759",
    weapon1OffSigilId: "24615",
    weapon2MainId: "46766",
    weapon2MainSigil1Id: "84505",
    weapon2OffId: "46766",
    weapon2OffSigilId: "24639"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "In a more organized setup you can run an Axe with ", React.createElement(Item, {
    name: "elementalslaying",
    id: "24661"
  }), " and an offhand Warhorn with ", React.createElement(Item, {
    name: "paralyzation",
    id: "24639"
  }), " to blast ", React.createElement(Boon, {
    name: "Might"
  }), " on 2nd and 4th island."), "\n", React.createElement(_components.li, null, "Play ", React.createElement(Trait, {
    name: "savageinstinct",
    id: "1977"
  }), "!"), "\n")), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "100%-66%")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Tremor",
    id: "14415"
  }), " and ", React.createElement(Skill, {
    name: "Pommelbash",
    id: "14503"
  }), ", ", React.createElement(Skill, {
    name: "Headbutt",
    id: "30343"
  }), "."), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Berserk",
    id: "30185"
  }), "."), "\n", React.createElement(_components.li, null, "Swap weapons."), "\n", React.createElement(_components.li, null, "Continue as usual. In fast group you want to shorten your used skills to:", "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "decapitate",
    id: "30851"
  }), "."), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "cycloneaxe",
    id: "14421"
  }), ", ", React.createElement(Skill, {
    name: "dualstrike",
    id: "14418"
  }), "."), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "decapitate",
    id: "30851"
  }), "."), "\n"), "\n"), "\n", React.createElement(_components.li, null, "If your party is not the fastest pick up your banners and redrop them on the main island. Put them slightly outside of the stack so you don't take a portal on accident."), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.em, null, "Islands")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Swap to your warhorn or torch if you have it and use the blasts in fire fields for ", React.createElement(Boon, {
    name: "Might"
  }), " at 2nd and 4th island."), "\n", React.createElement(_components.li, null, "Don't use heavy cooldowns after the 3rd island."), "\n", React.createElement(_components.li, null, "On the last island shortly before the add dies use ", React.createElement(Skill, {
    name: "Mending",
    id: "14401"
  }), " and open ", React.createElement(Skill, {
    name: "Berserk",
    id: "30185"
  }), " already."), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "66%-33%")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Follow the standard fractal rotation."), "\n", React.createElement(_components.li, null, "If your party does not phase Skorvald without a dash, recast your banners."), "\n", React.createElement(_components.li, null, "Before you move to the 1st island pick your banners up and drop them on the main island. Put them slightly outside of the stack so you don't take a portal on accident."), "\n"), "\n", React.createElement(_components.p, null, "The rest of the fight follows accordingly."), "\n", React.createElement(Boss, {
    name: "artsariiv",
    video: "RBkM9TL4uxM",
    videoCreator: "Roach [dT]",
    foodId: "91805",
    utilityId: "9443",
    healId: "14401",
    utility1Id: "14405",
    utility2Id: "14407",
    utility3Id: "14404",
    eliteId: "30343",
    weapon1MainAffix: "Berserker",
    weapon1MainType: "Axe",
    weapon1MainSigil1: "Force",
    weapon1MainInfusion1Id: "37131",
    weapon1OffAffix: "Berserker",
    weapon1OffType: "Axe",
    weapon1OffSigil: "impact",
    weapon1OffInfusionId: "37131",
    weapon2MainAffix: "Berserker",
    weapon2MainType: "Mace",
    weapon2MainSigil1: "severance",
    weapon2MainInfusion1Id: "37131",
    weapon2OffAffix: "Berserker",
    weapon2OffType: "Mace",
    weapon2OffSigil: "paralyzation",
    weapon2OffInfusionId: "37131",
    video: "RBkM9TL4uxM",
    videoCreator: "Roach [dT]",
    foodId: "91805",
    utilityId: "9443",
    healId: "14401",
    utility1Id: "14405",
    utility2Id: "14407",
    utility3Id: "14404",
    eliteId: "30343",
    weapon1MainInfusion1Id: "37131",
    weapon1OffInfusionId: "37131",
    weapon2MainInfusion1Id: "37131",
    weapon2OffInfusionId: "37131",
    weapon1MainId: "46759",
    weapon1MainSigil1Id: "24615",
    weapon1OffId: "46759",
    weapon1OffSigilId: "24868",
    weapon2MainId: "46766",
    weapon2MainSigil1Id: "84505",
    weapon2OffId: "46766",
    weapon2OffSigilId: "24639"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Play ", React.createElement(Trait, {
    name: "savageinstinct",
    id: "1977"
  }), "!"), "\n", React.createElement(_components.li, null, "With ", React.createElement(Instability, {
    name: "Afflicted"
  }), " you can bring a meme skill ", React.createElement(Skill, {
    name: "Berserkerstance",
    id: "14406"
  }), "."), "\n", React.createElement(_components.li, null, "If most of the damage done is in the center, take ", React.createElement(Skill, {
    name: "signetoffury",
    id: "14410"
  }), "."), "\n")), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "100% in mid")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Use ", React.createElement(Skill, {
    name: "whirling axe",
    id: "14399"
  }), " or ", React.createElement(Skill, {
    name: "Headbutt",
    id: "30343"
  }), " to gain adrenaline fast."), "\n", React.createElement(_components.li, null, "Depending on your team you wanna engage ", React.createElement(Skill, {
    name: "Berserk",
    id: "30185"
  }), " before you use your ", React.createElement(SpecialActionKey, {
    name: "hypernovalaunch"
  }), " to get to Artsariiv."), "\n", React.createElement(_components.li, null, "Use ", React.createElement(Skill, {
    name: "Mending",
    id: "14401"
  }), " to regain eventually lost health and ", React.createElement(Trait, {
    name: "peakperformance",
    id: "1444"
  }), "."), "\n", React.createElement(_components.li, null, "Swap to your mace, be ready to ", React.createElement(Skill, {
    id: "14415",
    id: "14415"
  }), " as soon as the breakbar appears."), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.em, null, "Add Phases")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Pick up your banners and place them in the middle of the arena."), "\n", React.createElement(_components.li, null, "Help your party to CC the adds, preferable by CCing the one in the middle since you are there anyway."), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "66% and 33%")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "After the mid burn replace your banners at the corner Artsariiv is going to jump to."), "\n", React.createElement(_components.li, null, "Depending on your team's DPS you either wanna engage ", React.createElement(Skill, {
    name: "Berserk",
    id: "30185"
  }), " in the middle or in the corner."), "\n"), "\n", React.createElement(Boss, {
    name: "arkk",
    video: "fKGKMh41bWI",
    videoCreator: "Roach [dT]",
    foodId: "91805",
    utilityId: "50082",
    healId: "14401",
    utility1Id: "14405",
    utility2Id: "14407",
    utility3Id: "14404",
    eliteId: "14419",
    weapon1MainAffix: "Berserker",
    weapon1MainType: "Axe",
    weapon1MainSigil1: "Force",
    weapon1MainInfusion1Id: "37131",
    weapon1OffAffix: "Berserker",
    weapon1OffType: "Axe",
    weapon1OffSigil: "impact",
    weapon1OffInfusionId: "37131",
    weapon2OffAffix: "Berserker",
    weapon2OffType: "Mace",
    weapon2OffSigil: "severance",
    weapon2OffInfusionId: "37131",
    video: "fKGKMh41bWI",
    videoCreator: "Roach [dT]",
    foodId: "91805",
    utilityId: "50082",
    healId: "14401",
    utility1Id: "14405",
    utility2Id: "14407",
    utility3Id: "14404",
    eliteId: "14419",
    weapon1MainInfusion1Id: "37131",
    weapon1OffInfusionId: "37131",
    weapon2OffInfusionId: "37131",
    weapon1MainId: "46759",
    weapon1MainSigil1Id: "24615",
    weapon1OffId: "46759",
    weapon1OffSigilId: "24868",
    weapon2OffId: "46766",
    weapon2OffSigilId: "84505"
  }, React.createElement(Warning, null, React.createElement(_components.p, null, "It is not your job as ", React.createElement(Specialization, {
    name: "Berserker"
  }), " to slay the Temporal Anomaly!")), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Play ", React.createElement(Trait, {
    name: "smashbrawler",
    id: "2049"
  }), "!"), "\n")), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "1st Phase (100%-80%)")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Don't move your camera or character model until the Eye is gone or you will get ", React.createElement(Condition, {
    name: "Fear"
  }), "."), "\n", React.createElement(_components.li, null, "Engage ", React.createElement(Skill, {
    name: "berserk",
    id: "30185"
  }), " right away and start your normal rotation."), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.em, null, "Bloom phases")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Swap to offhand mace."), "\n", React.createElement(_components.li, null, "Dodge into the lightning."), "\n", React.createElement(_components.li, null, "Auto attack the bloom."), "\n", React.createElement(_components.li, null, "Use a ", React.createElement(Skill, {
    name: "Throw axe",
    profession: "warrior",
    id: "14398"
  }), " against the bloom to push it to the tower."), "\n", React.createElement(_components.li, null, "Dodge out."), "\n", React.createElement(_components.li, null, "Use ", React.createElement(Skill, {
    name: "mending",
    id: "14401"
  }), " to restore ", React.createElement(Item, {
    name: "scholar",
    id: "24836"
  }), "."), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "2nd Phase (80%-70%)")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Save your ", React.createElement(Skill, {
    name: "berserk",
    id: "30185"
  }), " for the 70%-50% burn."), "\n", React.createElement(_components.li, null, "Use ", React.createElement(Skill, {
    name: "tremor",
    id: "14415"
  }), " to gain ", React.createElement(Item, {
    name: "severance",
    id: "84505"
  }), "."), "\n", React.createElement(_components.li, null, "Finish up the phase with ", React.createElement(Skill, {
    name: "cycloneaxe",
    id: "14421"
  }), " and ", React.createElement(Skill, {
    name: "dualstrike",
    id: "14418"
  }), "."), "\n", React.createElement(_components.li, null, "If there is time left use ", React.createElement(Skill, {
    name: "whirlingaxe",
    id: "14399"
  }), "."), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.em, null, "Archdiviner")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Blast a fire field that is provided by the ", React.createElement(Specialization, {
    name: "Firebrand"
  }), " or ", React.createElement(Specialization, {
    name: "Weaver"
  }), " with your banners to stack 6 stacks of ", React.createElement(Boon, {
    name: "Might"
  }), "."), "\n", React.createElement(_components.li, null, "Engage ", React.createElement(Skill, {
    name: "berserk",
    id: "30185"
  }), " as soon as possible."), "\n", React.createElement(_components.li, null, "Don't stand inside of AoEs, that strip your boons."), "\n", React.createElement(_components.li, null, "Use ", React.createElement(Skill, {
    name: "mending",
    id: "14401"
  }), " when the Archdiviner is dead."), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "3rd Phase (70%-50%)")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Follow the warrior guide."), "\n", React.createElement(_components.li, null, "At 50% before you leave for your bloom, cast ", React.createElement(Skill, {
    name: "battlestandard",
    id: "14419"
  }), " in the middle."), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "4th Phase (50%-40%)")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Use ", React.createElement(Skill, {
    name: "tremor",
    id: "14415"
  }), " to gain ", React.createElement(Item, {
    name: "severance",
    id: "84505"
  }), "."), "\n", React.createElement(_components.li, null, "Engage ", React.createElement(Skill, {
    name: "berserk",
    id: "30185"
  }), "."), "\n", React.createElement(_components.li, null, "Finish the phase with business as usual."), "\n", React.createElement(_components.li, null, "Pick up ", React.createElement(Skill, {
    name: "Banner of Strength",
    id: "14405"
  }), " and ", React.createElement(Skill, {
    name: "Banner of Discipline",
    id: "14407"
  }), " at the of the phase."), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.em, null, "Gladiator Phase")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Recast ", React.createElement(Skill, {
    name: "Banner of Strength",
    id: "14405"
  }), ", ", React.createElement(Skill, {
    name: "Banner of Discipline",
    id: "14407"
  }), " into a fire field for 6 Stacks of ", React.createElement(Boon, {
    name: "Might"
  })), "\n", React.createElement(_components.li, null, "Do what a ", React.createElement(Specialization, {
    name: "Warrior"
  }), " does."), "\n", React.createElement(_components.li, null, "When the mob dies, precast ", React.createElement(Skill, {
    name: "mending",
    id: "14401"
  }), "."), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "5th Phase (40%-30%)")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Smash ", React.createElement(Skill, {
    name: "decapitate",
    id: "30851"
  }), " for as long as your ", React.createElement(Skill, {
    name: "berserk",
    id: "30185"
  }), " is still up."), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "6th Phase (30%-0%)")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Use ", React.createElement(Skill, {
    name: "tremor",
    id: "14415"
  }), " to gain ", React.createElement(Item, {
    name: "severance",
    id: "84505"
  }), "."), "\n", React.createElement(_components.li, null, "Engage ", React.createElement(Skill, {
    name: "berserk",
    id: "30185"
  }), "."), "\n", React.createElement(_components.li, null, "Finish the phase with business as usual."), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
